@import "./variables.scss";
a {
  text-decoration: none;
  // &:visited {
  //   color: inherit;
  // }
}
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.bm-page {
  position: fixed;
  width: 100%;
  height: 100%;
}
#root {
  position: absolute;
  width: 100%;
  height: 100%;
}
.bm-loader {
  position: fixed;
  width: 72px;
  height: 72px;
  transform: translate(-50%, -50%);
  left: 50vw;
  top: 33vh;
  animation: rotate 3s linear infinite;
}
@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.bm-button {
  padding:12px 0;
  background: #b03b34;

  font-weight: 600;
  font-size: ptvw(12);
  /* identical to box height, or 267% */

  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;

  color: #ffffff;
}

.center {
  text-align: center;
}
$max: 72;
$offset: 1;
@mixin list-loop($className, $styleName) {
  $i: 12;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i/(724/100)+vh};
    }
    $i: $i + $offset;
  }
}
@include list-loop(".text", "font-size");
// @include list-loop(".icon-", "width");
// @include list-loop(".icon-", "height");
